import { Component, OnDestroy, TemplateRef, ViewChild, } from '@angular/core';
import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';

import {
    ArticleManagementArticlesService,
    MasterDataKeywordsService,
    ArticleManagementMaterialsService,
    ArticleManagementSubComponentsService, ArticleManagementGraphicsService,
} from '../../../../../../../shared/api/index';
import * as XLSX from 'xlsx';
import {
    ArticleDetail, ArticleMaterial,
    ArticleMaterialComponent, ArticleRequiredFile, BrandLogoInfo, GraphicDetailsDto, GraphicListItem,
    KeywordInfo, MaterialListItem,
    Reply, ReplyGuid, RequiredFile,
    SubComponentSuggestItem,
    UpdateArticle,
    ViewModelItemArticleDetailArticleDetailChangeability, ViewModelItemArticleInfo, ViewModelListBrandLogoInfo, ViewModelListGraphicListItem,
    ViewModelListKeywordInfo,
    ViewModelListMaterialListItem, ViewModelListSubComponentSuggestItem,
} from '../../../../../../../shared/model';
import { map, filter, concatMap, mergeMap, toArray, } from 'rxjs/operators';
import { Subscription, Observable, from, of } from 'rxjs';
import { ARTICLE_PROCUREMENT_CATEGORIES, ArticleProcurementCategory } from '../../../../../../../shared/utils/procurement-categories';
import { ApplicationTimeService } from '../../../../../../../core/application-time.service';
import { StringConverterService } from '../../../../../../../shared/utils/string/string-converter.service';
import { CurrentUserService } from '../../../../../../../core/current-user.service';

type AOA = any[][];

interface Row {
    updateArticle?: UpdateArticle;
    articleGraphic?: GraphicDetailsDto;
    articleStatus?: ArticleDetail.StatusEnum;
    raw: any[];
    articleId?: string;
    articleGraphicId?: string;
    index: number;
}

const ARTICLE_NUMBER: number      = 0;
const ARTICLE_NAME: number        = 1;
const ARTICLE_DESCRIPTION: number = 49;
const ARTICLE_CATEGORY: number    = 50;
const ARTICLE_KEYWORD: number     = 51;

const APPLIED_BRAND_LOGO: number       = 2;
const PACKAGING_UNIT: number           = 3;
const ENGINEERING_PARTNER_NAME: number = 4;
const CUSTOMS_TARIFF_NUMBER: number    = 25;

const ASSEMBLED_DIMENSIONS_WIDTH: number  = 5;
const ASSEMBLED_DIMENSIONS_DEPTH: number  = 6;
const ASSEMBLED_DIMENSIONS_HEIGHT: number = 7;
const PACKED_DIMENSIONS_WIDTH: number     = 8;
const PACKED_DIMENSIONS_DEPTH: number     = 9;
const PACKED_DIMENSIONS_HEIGHT: number    = 10;
const ASSEMBLED_DIMENSIONS_WEIGHT: number = 11;
const PACKED_DIMENSIONS_WEIGHT: number    = 12;

const MATERIAL_NAME_1: number          = 13;
const MATERIAL_PERCENTAGE_1: number    = 14;
const MATERIAL_NAME_2: number          = 15;
const MATERIAL_PERCENTAGE_2: number    = 16;
const MATERIAL_NAME_3: number          = 17;
const MATERIAL_PERCENTAGE_3: number    = 18;
const MATERIAL_NAME_4: number          = 19;
const MATERIAL_PERCENTAGE_4: number    = 20;
const MATERIAL_NAME_5: number          = 21;
const MATERIAL_PERCENTAGE_5: number    = 22;
const MATERIAL_ADDITIONAL_INFO: number = 24;

const ARTICLE_REQUIRED_FILES_CERTIFICATE: number                    = 26;
const ARTICLE_REQUIRED_FILES_GA_DRAWING_DWG: number                 = 27;
const ARTICLE_REQUIRED_FILES_GA_DRAWING_PDF: number                 = 28;
const ARTICLE_REQUIRED_FILES_ARTICLE_INSTALLATION_GUIDELINE: number = 29;
const ARTICLE_REQUIRED_FILES_PREVIEW: number                        = 30;
const ARTICLE_REQUIRED_FILES_PICTURE: number                        = 31;
const ARTICLE_REQUIRED_FILES_3D_DRAWING: number                     = 32;
const ARTICLE_REQUIRED_FILES_SUB_COMPONENT_DRAWING_DWG: number      = 33;
const ARTICLE_REQUIRED_RISK_ASSESSMENT: number                      = 35;
const ARTICLE_REQUIRED_STABILITY_AND_LOAD_TESTING: number           = 36;

const ARTICLE_REQUIRED_FILES_FILEKINDS: Map<string, number> = new Map<string, number>( [
    [ 'F8625C8A-4CA5-4430-0371-EAA1E8CE1A39', ARTICLE_REQUIRED_FILES_CERTIFICATE ],
    [ 'FD6BAB5A-F634-F265-D11D-BA181368F1B1', ARTICLE_REQUIRED_FILES_GA_DRAWING_DWG ],
    [ '11B9CF08-97E9-A0C1-774D-0CB775BF6838', ARTICLE_REQUIRED_FILES_GA_DRAWING_PDF ],
    [ '3BC011CB-0D56-47F1-BE4E-ED865007C11F', ARTICLE_REQUIRED_FILES_ARTICLE_INSTALLATION_GUIDELINE ],
    [ 'B1C57DDC-79C8-448E-2099-85A27F2ABC39', ARTICLE_REQUIRED_FILES_PREVIEW ],
    [ '9FE2AF2B-CB05-69BA-548D-A6CA612DAA73', ARTICLE_REQUIRED_FILES_PICTURE ],
    [ '820D2A0F-66EA-4A2F-B7C2-A762020AB205', ARTICLE_REQUIRED_FILES_3D_DRAWING ],
    [ '0A1D3C0E-CCBD-B96F-66E9-5BA98A670313', ARTICLE_REQUIRED_FILES_SUB_COMPONENT_DRAWING_DWG ],
    [ 'E5EDD873-D519-45EA-9180-94805ED8A022', ARTICLE_REQUIRED_RISK_ASSESSMENT ],
    [ '4089D16A-9672-4E57-BFC5-42782CD080B3', ARTICLE_REQUIRED_STABILITY_AND_LOAD_TESTING ],
] );

const SUB_COMPONENTS: number = 34;

const ARTICLE_GRAPHIC_NAME: number               = 39;
const ARTICLE_GRAPHIC_AREA_WIDTH: number         = 40;
const ARTICLE_GRAPHIC_AREA_HEIGHT: number        = 41;
const ARTICLE_GRAPHIC_VISUAL_AREA_WIDTH: number  = 42;
const ARTICLE_GRAPHIC_VISUAL_AREA_HEIGHT: number = 43;
const ARTICLE_GRAPHIC_MATERIAL: number           = 44;
const ARTICLE_GRAPHIC_THICKNESS: number          = 45;
const ARTICLE_GRAPHIC_NOTE: number               = 46;

const ARTICLE_STATUS_DEVELOPMENT: number           = 52;
const ARTICLE_STATUS_ACTIVE_IN_PRE_ROLLOUT: number = 53;
const ARTICLE_STATUS_ACTIVE_IN_ROLLOUT: number     = 54;
const ARTICLE_STATUS_INACTIVE: number              = 55;
const ARTICLE_STATUS_CHANGE_REASON: number         = 56;

const ARTICLE_STATUS_MAP: Map<number, ArticleDetail.StatusEnum> = new Map<number, ArticleDetail.StatusEnum>( [
    [ ARTICLE_STATUS_DEVELOPMENT, ArticleDetail.StatusEnum.Development ],
    [ ARTICLE_STATUS_ACTIVE_IN_PRE_ROLLOUT, ArticleDetail.StatusEnum.ActiveInPreRollout ],
    [ ARTICLE_STATUS_ACTIVE_IN_ROLLOUT, ArticleDetail.StatusEnum.ActiveInRollout ],
    [ ARTICLE_STATUS_INACTIVE, ArticleDetail.StatusEnum.Inactive ],
] );

const HEADER: string[] = [
    'Article Number',
    'Article Name',
    'Applied Brand Logo',
    'Packaging Unit',
    'Enginering Partner',
    'Assembled Dimensions Width',
    'Assembled Dimensions Depth',
    'Assembled Dimensions Height',
    'Packed Dimensions Width',
    'Packed Dimensions Depth',
    'Packed Dimensions Height',
    'Weight Assembled',
    'Weight Packed',
    'Material',
    '%',
    'Material',
    '%',
    'Material',
    '%',
    'Material',
    '%',
    'Material',
    '%',
    '-',
    'Additional Info',
    'Customs Tariff Number',
    'Certificate',
    'GA Drawing [DWG]',
    'GA Drawing [PDF]',
    'Article Installation Guideline [PDF]',
    'Rendering (RSP Preview)',
    'Picture',
    '3D Drawing',
    'Sub-component Drawing',
    'Subcomponent Numbers',
    'Retail Hardware Risk Assessment',
    'Stability and load testing',
    '-',
    '-',
    'Graphic Name',
    'Article Graphic Area Width',
    'Article Graphic Area Height',
    'Visual Graphic Area Width',
    'Visual Graphic Area Height',
    'Graphic Material',
    'Graphic Thickness',
    'Graphic Note',
    '-',
    '-',
    'Description',
    'Procurement Category',
    'Keyword',
    'DEVELOPMENT',
    'ACTIVE IN PRE-ROLL OUT',
    'ACTIVE IN ROLL OUT',
    'Reaon to change',
    '-',
    '-',
];

@Component( {
    selector:    'rsp-create-article-from-excel-dialog',
    templateUrl: './create-article-from-excel-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        '../../../../../../../shared/scss/05_module/simple-list.scss',
        './create-article-from-excel-dialog.scss',
    ],
} )
export class CreateArticleFromExcelDialogComponent implements OnDestroy {

    @ViewChild( 'headline', { read: TemplateRef, static: true } ) headlineTemplate: TemplateRef<any>;
    @ViewChild( 'content', { read: TemplateRef, static: true } ) contentTemplate: TemplateRef<any>;
    @ViewChild( 'footer', { read: TemplateRef, static: true } ) footerTemplate: TemplateRef<any>;

    isLoading: Subscription;

    isImporting: boolean = false;
    isDone: boolean      = false;
    progress: string[];
    index: number        = 1;

    caption: string;


    constructor(
        private articlesService: ArticleManagementArticlesService,
        private articlesMaterialService: ArticleManagementMaterialsService,
        private dialogService: DialogService,
        private masterdataKeywords: MasterDataKeywordsService,
        private subcomponentsService: ArticleManagementSubComponentsService,
        private graphicsApi: ArticleManagementGraphicsService,
    ) {
    }

    ngOnDestroy(): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }


    importArticleMetadataFromFile( files: File[] ): void {

        if ( files.length !== 1 ) {
            throw new Error( 'Cannot use multiple files' );
        }

        this.isImporting = true;

        const reader: FileReader = new FileReader();
        reader.onload            = ( e: any ) => {
            /* read workbook */
            let bstr: string        = '';
            const bytes: Uint8Array = new Uint8Array( e.target.result );
            for ( let i: number = 0; i < bytes.byteLength; i++ ) {
                bstr += String.fromCharCode( bytes[ i ] );
            }

            const wb: XLSX.WorkBook = XLSX.read( bstr, { type: 'binary', cellDates: true } );

            /* grab sheet */
            const wsname: string     = wb.SheetNames[ 1 ];
            const ws: XLSX.WorkSheet = wb.Sheets[ wsname ];

            /* save data */
            const importedData: AOA  = <AOA> XLSX.utils.sheet_to_json( ws, { header: 1, raw: true } );
            const processedData: AOA = [];
            processedData.push( HEADER );

            this.isLoading = from( importedData )
                .pipe(
                    map( ( row: any[], index: number ): Row => {
                        return {
                            raw:       row,
                            index:     index,
                            articleId: '00000000-0000-0000-0000-000000000000',
                        };
                    } ),
                    // Skip Header
                    filter( ( row: Row ) => {
                        return row.index > 8;
                    } ),
                    // Prozess rows
                    concatMap( ( row: Row ) => {
                        return this.getOrCreateArticle( row );
                    } ),
                    filter( ( row: Row ) => {
                        return row.articleId !== '00000000-0000-0000-0000-000000000000';
                    } ),
                    concatMap( ( row: Row ) => {
                        return this.getArticleDetails( row );
                    } ),
                    concatMap( ( row: Row ) => {
                        return this.setKeyword( row );
                    } ),
                    concatMap( ( row: Row ) => {
                        return this.setMaterials( row );
                    } ),
                    concatMap( ( row: Row ) => {
                        return this.setAppliedBrandLogo( row );
                    } ),
                    concatMap( ( row: Row ) => {
                        return this.setProcurementCategory( row );
                    } ),
                    concatMap( ( row: Row ) => {
                        return this.setRequiredFiles( row );
                    } ),
                    concatMap( ( row: Row ) => {
                        return this.setSubComponents( row );
                    } ),
                    concatMap( ( row: Row ) => {
                        return this.updateArticleDetails( row );
                    } ),
                    concatMap( ( row: Row ) => {
                        return this.loadGraphic( row );
                    } ),
                    concatMap( ( row: Row ) => {
                        return this.setGraphicMaterial( row );
                    } ),
                    concatMap( ( row: Row ) => {
                        return this.updateGraphic( row );
                    } ),
                    // concatMap( ( row: Row ) => {
                    //     return this.updateArticleStatus( row );
                    // } ),
                ).subscribe( ( row: Row ) => {
                        processedData.push( row.raw );
                    },
                             ( error: any ) => {
                        console.log( error );
                    },       () => {

                        this.progress.sort( ( a: string, b: string ) => {
                            return a.localeCompare( b );
                        } );
                        this.progress.push( 'Import DONE.' );

                        const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet( processedData );
                        const workBook: XLSX.WorkBook   = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet( workBook, workSheet, 'Import Result' );

                        const log: string[][] = this.progress.map( ( value: string ) => {
                            return [ value ];
                        } );

                        const workSheetLog: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet( log );
                        XLSX.utils.book_append_sheet( workBook, workSheetLog, 'Import Log' );

                        XLSX.writeFile( workBook, 'IMPORT_RESULT_' + files[ 0 ].name );

                        this.isDone      = true;
                        this.isImporting = false;
                    },
                );
        };

        reader.readAsArrayBuffer( files[ 0 ] );

    }

    open( createFromSelection: boolean = false ): void {
        this.isImporting = false;
        this.progress    = [];
        this.isDone      = false;
        this.index       = 1;
        this.caption     = 'Create or update Article from excel';
        this.dialogService.openDialog( {
            contentTemplate:  this.contentTemplate,
            headlineTemplate: this.headlineTemplate,
            footerTemplate:   this.footerTemplate,
            withBackdrop:     true,
        } );
    }

    close(): void {
        this.dialogService.closeDialog();
    }

    private getArticleIdByNumber( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ] || row.raw[ ARTICLE_NAME ];
        if ( row.raw[ ARTICLE_NAME ] && row.raw[ ARTICLE_NAME ].length > 73 ) {
            this.progress.push( articleNumber + ' #01: Article name "' + row.raw[ ARTICLE_NAME ] + '" longer than 73 characters. Ignoring.' );
            row.articleId = '00000000-0000-0000-0000-000000000000';
            return of( row );
        } else {
            return this.articlesService.articlesGetArticleIdByNumber( articleNumber )
                       .pipe(
                           map( ( id: string ) => {
                               row.articleId = id;
                               if ( id !== '00000000-0000-0000-0000-000000000000' ) {
                                   this.progress.push( articleNumber + ' #01: found.' );
                               } else {
                                   this.progress.push( articleNumber + ' #01: not found, ignoring.' );
                               }
                               return row;
                           } ),
                       );
        }
    }

    private createArticle( row: Row ): Observable<Row> {
        let result: Observable<Row> = of( row );

        const articleNumber: string = row.raw[ ARTICLE_NUMBER ] || row.raw[ ARTICLE_NAME ];

        this.progress.push( articleNumber + ' #01: Creating new Article.' );
        result = this.articlesService.articlesCreate( row.raw[ ARTICLE_NAME ] )
                     .pipe(
                         map( ( articleInfo: ViewModelItemArticleInfo ) => {
                             row.articleId             = articleInfo.data.id;
                             row.raw[ ARTICLE_NUMBER ] = articleInfo.data.number;
                             this.progress.push( articleInfo.data.number + ' #01: Created.' );
                             return row;
                         } ),
                     );

        return result;
    }

    private getArticleDetails( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ];
        this.progress.push( articleNumber + ' #02: get current metadata.' );

        return this.articlesService.articlesGetArticleDetail( row.articleId )
                   .pipe(
                       concatMap( ( result: ViewModelItemArticleDetailArticleDetailChangeability ) => {
                           const articleDetail: ArticleDetail = result.data;
                           row.updateArticle                  = {
                               articleId:              articleDetail.id,
                               name:                   articleDetail.name,
                               description:            articleDetail.description,
                               category:               articleDetail.category,
                               packagingUnit:          articleDetail.packagingUnit,
                               customsTariffNumber:    articleDetail.customsTariffNumber,
                               note:                   articleDetail.note,
                               appliedBrandLogoId:     articleDetail.appliedBrandLogo ? articleDetail.appliedBrandLogo.id : null,
                               spmGroupId:             articleDetail.spmGroup ? articleDetail.spmGroup.id : null,
                               spmTypeId:              articleDetail.spmType ? articleDetail.spmType.id : null,
                               requiredFiles:          articleDetail.requiredFiles.map( ( requiredFile: ArticleRequiredFile ): RequiredFile => {
                                   return { fileKindId: requiredFile.fileKindId, isRequired: requiredFile.isRequired };
                               } ),
                               needsCapacity:          articleDetail.needsCapacity,
                               capacities:             articleDetail.capacities,
                               subComponentIds:        [],
                               keywordIds:             [],
                               materials:              articleDetail.materials.map( ( material: ArticleMaterial ): ArticleMaterialComponent => {
                                   return { materialId: material.materialId, percentage: material.percentage };
                               } ),
                               materialInfo:           articleDetail.materialInfo,
                               legacyArticleNumber:    articleDetail.legacyNumber,
                               articleDimensions:      {
                                   assembledDimensions: articleDetail.assembledDimensions,
                                   packedDimensions:    articleDetail.packedDimensions,
                               },
                               engineeringPartnerName: articleDetail.engineeringPartnerName,
                           };

                           row.articleStatus = articleDetail.status;

                           return of( row );
                       } ),
                   );
    }

    private getOrCreateArticle( row: Row ): Observable<Row> {
        let result: Observable<Row> = of( row );

        if ( row.raw[ ARTICLE_NUMBER ] && row.raw[ ARTICLE_NAME ] ) {
            result = this.getArticleIdByNumber( row );
        } else if ( row.raw[ ARTICLE_NAME ] ) {
            result = this.createArticle( row );
        }

        return result;
    }

    private setKeyword( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ];

        return this.masterdataKeywords.keywordsGetOptions( '250EA06E-0454-48C8-B8EA-3BD9A5C13C9B' )
                   .pipe(
                       map( ( keywords: ViewModelListKeywordInfo ) => {
                           const matchingKeyword: KeywordInfo = keywords.data.find( ( keyword: KeywordInfo ) => {
                               return keyword.name === row.raw[ ARTICLE_KEYWORD ];
                           } );
                           if ( matchingKeyword ) {
                               row.updateArticle.keywordIds = [ matchingKeyword.id ];
                               this.progress.push( articleNumber + ' #03: Keyword set.' );
                           } else {
                               this.progress.push( articleNumber + ' #03: Keyword "' + row.raw[ ARTICLE_KEYWORD ] + '" not found. Ignoring.' );
                           }
                           return row;
                       } ),
                   );
    }

    private setMaterials( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ];

        return this.articlesMaterialService.materialsGetList().pipe(
            map( ( materials: ViewModelListMaterialListItem ) => {
                const materialNames: number[] = [ MATERIAL_NAME_1, MATERIAL_NAME_2, MATERIAL_NAME_3, MATERIAL_NAME_4, MATERIAL_NAME_5 ];

                row.updateArticle.materials = [];

                for ( const materialName of materialNames ) {
                    if ( row.raw[ materialName ] ) {
                        const matchingMaterial: MaterialListItem = materials.data.find( ( material: MaterialListItem ) => {
                            return material.name === row.raw[ materialName ];
                        } );

                        const percentage: number = parseInt( row.raw[ materialName + 1 ], 10 );

                        if ( matchingMaterial ) {
                            if ( (percentage && percentage > 0) ) {
                                row.updateArticle.materials.push( {
                                    materialId: matchingMaterial.id,
                                    percentage: percentage,
                                } );
                            } else {
                                // tslint:disable-next-line:max-line-length
                                this.progress.push( articleNumber + ' #04: Percentage for Material "' + row.raw[ materialName ] + '" not found or 0. Ignoring.' );
                            }
                        } else {
                            this.progress.push( articleNumber + ' #04: Material "' + row.raw[ materialName ] + '" not found. Ignoring.' );
                        }
                    }
                }

                this.progress.push( articleNumber + ' #04: Materials set.' );
                row.updateArticle.materialInfo = row.raw[ MATERIAL_ADDITIONAL_INFO ];

                return row;
            } ),
        );
    }

    private setAppliedBrandLogo( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ];

        return this.articlesService.articlesGetBrandLogoOptionsByArticleId( row.articleId )
                   .pipe(
                       map( ( brandLogos: ViewModelListBrandLogoInfo ) => {
                           const matchingLogo: BrandLogoInfo = brandLogos.data.find( ( brandLogo: BrandLogoInfo ) => {
                               return brandLogo.name === row.raw[ APPLIED_BRAND_LOGO ];
                           } );
                           if ( matchingLogo ) {
                               this.progress.push( articleNumber + ' #05: Applied Brand Logo set.' );
                               row.updateArticle.appliedBrandLogoId = matchingLogo.id;
                           } else {
                               this.progress.push( articleNumber + ' #05: Applied Brand Logo "' + row.raw[ APPLIED_BRAND_LOGO ] + '" not found. Ignoring.' );
                           }
                           return row;
                       } ),
                   );
    }

    private setProcurementCategory( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ];

        const procurementCategory: ArticleProcurementCategory = ARTICLE_PROCUREMENT_CATEGORIES.find( ( category: ArticleProcurementCategory ) => {
            return category.name === row.raw[ ARTICLE_CATEGORY ];
        } );

        if ( procurementCategory ) {
            row.updateArticle.category = procurementCategory.name;
            this.progress.push( articleNumber + ' #06: Category set.' );
        } else {
            this.progress.push( articleNumber + ' #06: Category "' + row.raw[ ARTICLE_CATEGORY ] + '" not found. Ignoring.' );
        }

        return of( row );
    }

    private setRequiredFiles( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ];

        row.updateArticle.requiredFiles = row.updateArticle.requiredFiles.map( ( requiredFile: RequiredFile ) => {
            const fileKindId: string = requiredFile.fileKindId.toUpperCase();

            if ( ARTICLE_REQUIRED_FILES_FILEKINDS.has( fileKindId ) ) {
                const requiredFileColumnIndex: number = ARTICLE_REQUIRED_FILES_FILEKINDS.get( fileKindId );
                requiredFile.isRequired               = row.raw[ requiredFileColumnIndex ] === 'yes';
            }

            return requiredFile;
        } );

        this.progress.push( articleNumber + ' #07: Required Files set.' );
        return of( row );
    }

    private setSubComponents( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ];

        if ( row.raw[ SUB_COMPONENTS ] ) {
            const subComponentNumbers: string[] = row.raw[ SUB_COMPONENTS ].split( ',' ).map( ( component: string ) => component.trim() );

            return from( subComponentNumbers ).pipe(
                mergeMap( ( subComponentNumber: string ) =>
                    this.subcomponentsService.subComponentsSuggest( {
                        term: subComponentNumber,
                        size: 1000,
                        page: 1,
                    } ).pipe(
                        map( ( subComponents: ViewModelListSubComponentSuggestItem ) => {
                            let match: SubComponentSuggestItem = subComponents.data.find( ( subComponent: SubComponentSuggestItem ) => {
                                return subComponent.number === subComponentNumber;
                            } );
                            if ( !match ) {
                                match = {
                                    id: 'error#' + subComponentNumber,
                                };
                            }
                            this.progress.push( articleNumber + ' #08: SubComponent "' + subComponentNumber + '" found.' );
                            return match.id;
                        } ),
                    ),    4 ),
                mergeMap( ( subComponentNumber: string ) => {
                    console.log( subComponentNumber );
                    if ( subComponentNumber.startsWith( 'error#' ) ) {
                        return this.subcomponentsService.subComponentsCreateSubComponent( subComponentNumber.split('#')[1] )
                            .pipe(
                                map( ( reply: ReplyGuid ) => {
                                    this.progress.push( articleNumber + ' #08: SubComponent "' + subComponentNumber.split('#')[1] + '" created.' );
                                    return reply.value;
                                } ) );
                    } else {
                        return of ( subComponentNumber );
                    } },  4 ),
                toArray(),
                map( ( subComponents: string[] ) => {
                    row.updateArticle.subComponentIds = subComponents;
                    this.progress.push( articleNumber + ' #08: Sub-Components set.' );

                    return row;
                } ),
            );
        } else {
            return of( row );
        }
    }

    private updateArticleDetails( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ];

        if ( row.raw[ ARTICLE_DESCRIPTION ] ) {
            const description: string = `Update from Metadata import on ${ApplicationTimeService.current().toUTCString()}:\n ${row.raw[ ARTICLE_DESCRIPTION ]}`;
            if ( row.updateArticle.description ) {
                row.updateArticle.description = row.updateArticle.description + '\n' + description;
            } else {
                row.updateArticle.description = description;
            }
        }

        if ( row.raw[ PACKAGING_UNIT ] && parseInt( row.raw[ PACKAGING_UNIT ], 10 ) > 0 ) {
            row.updateArticle.packagingUnit = row.raw[ PACKAGING_UNIT ];
        }

        row.updateArticle.name                   = row.raw[ ARTICLE_NAME ];
        row.updateArticle.category               = row.raw[ ARTICLE_CATEGORY ];
        row.updateArticle.customsTariffNumber    = row.raw[ CUSTOMS_TARIFF_NUMBER ];
        // ToDo: row.updateArticle.note = row.raw[ ARTICLE_DESCRIPTION ];
        row.updateArticle.engineeringPartnerName = row.raw[ ENGINEERING_PARTNER_NAME ];
        row.updateArticle.articleDimensions      = {
            assembledDimensions: {
                width:  row.raw[ ASSEMBLED_DIMENSIONS_WIDTH ],
                depth:  row.raw[ ASSEMBLED_DIMENSIONS_DEPTH ],
                height: row.raw[ ASSEMBLED_DIMENSIONS_HEIGHT ],
                weight: row.raw[ ASSEMBLED_DIMENSIONS_WEIGHT ],
            },
            packedDimensions:    {
                width:  row.raw[ PACKED_DIMENSIONS_WIDTH ],
                depth:  row.raw[ PACKED_DIMENSIONS_DEPTH ],
                height: row.raw[ PACKED_DIMENSIONS_HEIGHT ],
                weight: row.raw[ PACKED_DIMENSIONS_WEIGHT ],
            },
        };

        return this.articlesService.articlesUpdateDetail( row.articleId, row.updateArticle )
                   .pipe(
                       map( ( result: Reply ) => {
                           this.progress.push( articleNumber + ' #09: Updated metadata.' );
                           return row;
                       } ),
                   );
    }

    private loadGraphic( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ];

        let result: Observable<Row> = of( row );

        if ( !row.raw[ ARTICLE_GRAPHIC_NAME ] ) {
            this.progress.push( articleNumber + ' #10: Graphic not defined. Skipping.' );
        } else {
            result = this.articlesService.articlesGetGraphicListItemsByArticleId( row.articleId )
                         .pipe(
                             map( ( graphics: ViewModelListGraphicListItem ) => {
                                 const matchingGraphic: GraphicListItem = graphics.data.find( ( graphic: GraphicListItem ) => {
                                     return graphic.name === row.raw[ ARTICLE_GRAPHIC_NAME ];
                                 } );
                                 if ( matchingGraphic ) {
                                     this.progress.push( articleNumber + ' #10: Graphic loaded.' );

                                     row.articleGraphicId = matchingGraphic.id;
                                     row.articleGraphic   = {
                                         materialId:                       matchingGraphic.material ? matchingGraphic.material.id : null,
                                         name:                             matchingGraphic.name,
                                         note:                             matchingGraphic.note,
                                         thickness:                        matchingGraphic.thickness,
                                         graphicAreaDimensionsWidth:       matchingGraphic.graphicArea ? matchingGraphic.graphicArea.width : null,
                                         graphicAreaDimensionsHeight:      matchingGraphic.graphicArea ? matchingGraphic.graphicArea.height : null,
                                         visiblePrintAreaDimensionsWidth:  matchingGraphic.visiblePrintArea ? matchingGraphic.visiblePrintArea.width : null,
                                         visiblePrintAreaDimensionsHeight: matchingGraphic.visiblePrintArea ? matchingGraphic.visiblePrintArea.height : null,
                                         templateFileContainerId:          matchingGraphic.templateFile ? matchingGraphic.templateFile.id : null,
                                     };
                                 }
                                 return row;
                             } ),
                             concatMap( ( updatedRow: Row ): Observable<Row> => {
                                 let graphicResult: Observable<Row> = of( updatedRow );
                                 if ( !row.articleGraphicId ) {
                                     const tempGraphic: { name: string } = { name: updatedRow.raw[ ARTICLE_GRAPHIC_NAME ] };
                                     graphicResult                       = this.articlesService
                                                                               .articlesCreateGraphic( updatedRow.articleId, tempGraphic )
                                                                               .pipe(
                                                                                   map( ( reply: ReplyGuid ) => {
                                                                                       this.progress.push( articleNumber + ' #10: Graphic created.' );
                                                                                       updatedRow.articleGraphicId = reply.value;
                                                                                       updatedRow.articleGraphic   = tempGraphic;
                                                                                       return updatedRow;
                                                                                   } ),
                                                                               );
                                 }

                                 return graphicResult;
                             } ),
                         );
        }

        return result;
    }

    private setGraphicMaterial( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ];

        let result: Observable<Row> = of( row );

        if ( row.articleGraphicId ) {
            result = this.articlesMaterialService.materialsGetList().pipe(
                map( ( materials: ViewModelListMaterialListItem ) => {

                    const matchingMaterial: MaterialListItem = materials.data.find( ( material: MaterialListItem ) => {
                        return material.name === row.raw[ ARTICLE_GRAPHIC_MATERIAL ];
                    } );

                    if ( matchingMaterial ) {
                        row.articleGraphic.materialId = matchingMaterial.id;

                    } else {
                        this.progress.push( articleNumber + ' #11: Graphic Material "' + row.raw[ ARTICLE_GRAPHIC_MATERIAL ] + '" not found. Ignoring.' );
                    }

                    this.progress.push( articleNumber + ' #11: Graphic Material set.' );

                    return row;
                } ),
            );
        }

        return result;
    }

    private updateGraphic( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ];

        let result: Observable<Row> = of( row );

        if ( row.articleGraphicId ) {
            row.articleGraphic.note                             = row.raw[ ARTICLE_GRAPHIC_NOTE ];
            row.articleGraphic.thickness                        = row.raw[ ARTICLE_GRAPHIC_THICKNESS ];
            row.articleGraphic.graphicAreaDimensionsWidth       = row.raw[ ARTICLE_GRAPHIC_AREA_WIDTH ];
            row.articleGraphic.graphicAreaDimensionsHeight      = row.raw[ ARTICLE_GRAPHIC_AREA_HEIGHT ];
            row.articleGraphic.visiblePrintAreaDimensionsWidth  = row.raw[ ARTICLE_GRAPHIC_VISUAL_AREA_WIDTH ];
            row.articleGraphic.visiblePrintAreaDimensionsHeight = row.raw[ ARTICLE_GRAPHIC_VISUAL_AREA_HEIGHT ];

            result = this.graphicsApi.graphicsUpdateGraphic( row.articleGraphicId, row.articleGraphic )
                         .pipe(
                             map( ( reply: Reply ) => {
                                 this.progress.push( articleNumber + ' #12: Graphic updated.' );
                                 return row;
                             } ),
                         );
        }

        return result;
    }

    private updateArticleStatus( row: Row ): Observable<Row> {
        const articleNumber: string = row.raw[ ARTICLE_NUMBER ];

        let targetStatus: ArticleDetail.StatusEnum = row.articleStatus;

        const now: Date        = ApplicationTimeService.current();
        const status: number[] = [
            ARTICLE_STATUS_DEVELOPMENT,
            ARTICLE_STATUS_ACTIVE_IN_PRE_ROLLOUT,
            ARTICLE_STATUS_ACTIVE_IN_ROLLOUT,
            ARTICLE_STATUS_INACTIVE,
        ];

        let statusDates: { key: number, value: Date }[] = [];

        for ( const columnIndex of status ) {
            if ( row.raw[ columnIndex ] ) {
                const stateChangeDate: Date = row.raw[ columnIndex ];
                statusDates.push( { key: columnIndex, value: stateChangeDate } );
            }
        }

        statusDates = statusDates.sort( ( a: { key: number, value: Date }, b: { key: number, value: Date } ) => {
            return b.value.getTime() - a.value.getTime();
        } );

        for ( const statusDate of statusDates ) {
            if ( statusDate.value <= now ) {
                targetStatus = ARTICLE_STATUS_MAP.get( statusDate.key );
                break;
            }
        }

        let result: Observable<Row> = of( row );

        if ( targetStatus !== row.articleStatus ) {
            this.progress.push( articleNumber + ' #13: Status changed to ' + StringConverterService.splitCamelcaseWithSpace(
                ArticleDetail.StatusEnum.toString( targetStatus ) ) + '.' );

            result = this.articlesService.articlesUpdateStatus( row.articleId, String( targetStatus ), row.raw[ ARTICLE_STATUS_CHANGE_REASON ] || '' )
                         .pipe(
                             map( ( response: Reply ) => {
                                 return row;
                             } ),
                         );
        }

        return result;
    }

}
